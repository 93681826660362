export const MODAL_ADD_USER = 'ADD_USER';
export const MODAL_ACTIVE_USER = 'ACTIVE_USER';
export const MODAL_DISABLED_USER = 'DISABLED_USER';
export const MODAL_ACTIVE_ADMIN = 'ACTIVE_ADMIN';
export const MODAL_DISABLED_ADMIN = 'DISABLED_ADMIN';

export const NAME_PROMOTION_FICC = 'FICC';
export const NAME_PROMOTION_SICC = 'SICC';

export const PromotionsNamesArray = [
  NAME_PROMOTION_FICC,
  NAME_PROMOTION_SICC,
];
