import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import FoundProjectView from 'views/User/FoundProject';
import VideoModal from 'components/Modal/VideoModal';
import { useSelector } from 'react-redux';
import { selectUserAccount } from 'store/User';
import handlePromise from 'utils/Promise';
import ProjectService from 'services/Projects';
import { FundChart, KpiResumeProject } from 'types/Project';
import { useParams } from 'react-router-dom';
import { getFile, getFileFromBase64 } from 'utils/downloadBase64';
import { LoadingContext } from 'context/Loading';

function FoundProject() {
  const { promocionId, id } = useParams();
  const userAccountId = useSelector( selectUserAccount );
  const { toggleLoading } = useContext( LoadingContext );
  const [showVideoModal, setVideoModal] = useState<{
    status: boolean;
    key:number ;
    index:number
  }>( {
    status: false, key: -1, index: -1,
  } );
  const [kpiProject, setKpiProject] = useState<KpiResumeProject | null>( null );
  const [project, setProjectData] = useState<KpiResumeProject | null>( null );
  const [fund, setFundData] = useState<any>();
  const [totalIncome, setTotalIncome] = useState<FundChart | undefined>();
  const [investmentStructure, setInvestmentStructure] = useState<FundChart | undefined>( );
  const [documents, setDocuments] = useState<[] | undefined>( );
  const [periods, setPeriods] = useState( [] );
  const [photographicAdvance, setPhotographicAdvance] = useState<[] | undefined>( );
  const [workProgress, setWorkProgress] = useState<{ conceptosAvanceObra: any[] | undefined;
    avanceGeneral: number }>(
    { conceptosAvanceObra: undefined, avanceGeneral: 0 },
  );

  const openVideoModal = useCallback( ( index: any, key: any ) => {
    setVideoModal( {
      status: true,
      key,
      index,
    } );
  }, [photographicAdvance] );

  const closeVideoModal = useCallback( () => {
    setVideoModal( {
      status: false,
      key: -1,
      index: -1,
    } );
  }, [] );

  const getAvanceFotografico = useCallback( async ( key: any ) => {
    const [, photographicAdvanceResponse, photographicAdvanceResponseData] = await handlePromise(
      ProjectService.getAvanceFotografico( userAccountId, id, key ),
    );
    if ( photographicAdvanceResponse.ok ) {
      setPhotographicAdvance( photographicAdvanceResponseData );
    }
  }, [userAccountId, id] );

  const downloadFile = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getUltimoInformeFinancieroPromocion( userAccountId, id ),
    );
    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFileFromBase64( downloadDocumentData, `${kpiProject?.codigoPromocion}_ultimo-informe-financiero` );
    }
  }, [userAccountId, id, kpiProject] );

  const downloadFilePdf = useCallback( async ( ) => {
    toggleLoading( true );
    const [, , downloadDocumentData] = await handlePromise(
      ProjectService.getInformePdfByPromocion( userAccountId, id, { responseType: 'blob' } ),
    );

    setTimeout( () => {
      toggleLoading( false );
    }, 800 );

    if ( downloadDocumentData ) {
      getFile( downloadDocumentData, `${kpiProject?.codigoPromocion}-informe-por-promoción`, 'application/pdf', 'pdf' );
    }
  }, [userAccountId, id, kpiProject] );

  const getData = useCallback( async () => {
    const [, fundResponse, fundData] = await handlePromise(
      ProjectService.getPromocionById( userAccountId, promocionId ),
    );
    if ( fundResponse.ok ) setFundData( fundData );
    const [, projectResponse, projectData] = await handlePromise(
      ProjectService.getProyectoFondoById( userAccountId, promocionId, id ),
    );
    if ( projectResponse.ok ) setProjectData( projectData );

    const [, kpiProjectResponse, kpiProjectData] = await handlePromise(
      ProjectService.getKPIProyectoById( userAccountId, promocionId, id ),
    );
    if ( kpiProjectResponse.ok ) setKpiProject( kpiProjectData );

    setWorkProgress( { conceptosAvanceObra: undefined, avanceGeneral: 0 } );
    const [, workProgressResponse, workProgressData] = await handlePromise(
      ProjectService.getAvanceProyecto( userAccountId, promocionId, id ),
    );
    console.log( 'workProgressData', workProgressData );
    const listAvanceObra: any[] = [];
    workProgressData.conceptosAvanceObra.forEach( ( item: any ) => {
      if ( item.nombre === 'ArquitecturaLicencias' ) {
        listAvanceObra.unshift( item );
      } else {
        listAvanceObra.push( item );
      }
    } );

    if ( workProgressResponse.ok ) {
      setWorkProgress( {
        avanceGeneral: workProgressData.avanceGeneral,
        conceptosAvanceObra: listAvanceObra,
      } );
    }

    setTotalIncome( undefined );
    const [, totalIncomeResponse, totalIncomeData] = await handlePromise(
      ProjectService.getIngresosTotales( userAccountId, promocionId, id ),
    );
    if ( totalIncomeResponse.ok ) {
      const totalIncomeFormatted = [];
      const total = ( totalIncomeData?.ingresosTotalesFaseOperativa || 0 )
          + ( totalIncomeData?.ventaActivoFaseSalida || 0 );

      if ( totalIncomeData.ingresosTotalesFaseOperativa ) {
        totalIncomeFormatted.push( {
          name: 'Ingresos totales (fase operativa)',
          value: totalIncomeData.ingresosTotalesFaseOperativa,
          percent: ( ( totalIncomeData?.ingresosTotalesFaseOperativa || 0 ) / total ) * 100,
        } );
      }
      if ( totalIncomeData.ventaActivoFaseSalida ) {
        totalIncomeFormatted.push( {
          name: 'Venta del activo (fase de salida)',
          value: totalIncomeData.ventaActivoFaseSalida,
          percent: ( ( totalIncomeData?.ventaActivoFaseSalida || 0 ) / total ) * 100,
        } );
      }
      setTotalIncome( totalIncomeFormatted );
    }

    setInvestmentStructure( undefined );
    const [, estructureResponse, estructureData] = await handlePromise(
      ProjectService.getEstructuraInversion( userAccountId, promocionId, id ),
    );
    if ( estructureResponse.ok ) {
      const estructureFormatted = [];
      const total = ( estructureData?.fondosPropios1 || 0 )
          + ( estructureData?.financiacionTotal || 0 );

      if ( estructureData.fondosPropios1 ) {
        estructureFormatted.push( {
          name: 'Fondos propios',
          value: estructureData.fondosPropios1,
          percent: ( ( estructureData?.fondosPropios1 || 0 ) / total ) * 100,
        } );
      }
      if ( estructureData.financiacionTotal ) {
        estructureFormatted.push( {
          name: 'Financiación total',
          value: estructureData.financiacionTotal,
          percent: ( ( estructureData?.financiacionTotal || 0 ) / total ) * 100,
        } );
      }
      setInvestmentStructure( estructureFormatted );
    }

    setDocuments( undefined );
    const [, documentProjectResponse, documentProjectData] = await handlePromise(
      ProjectService.getProjectFundDocumentacion( userAccountId, promocionId, id ),
    );
    if ( documentProjectResponse.ok ) setDocuments( documentProjectData );

    setPhotographicAdvance( undefined );
    setPeriods( [] );
    const [, periodsResponse, periodsData] = await handlePromise(
      ProjectService.getProjectFundAvanceFotografico( userAccountId, promocionId, id ),
    );
    if ( periodsResponse.ok && periodsData.length ) {
      setPeriods( periodsData );
      const { key } = periodsData[0];

      await getAvanceFotografico( key );
    }
  }, [userAccountId, id, promocionId] );

  useEffect( () => {
    getData();
  }, [userAccountId, id, promocionId] );

  console.log( 'workProgress', workProgress );
  return (
    <>
      <FoundProjectView
        projectData={project}
        fundData={fund}
        onOpenVideoModal={openVideoModal}
        kpiProject={kpiProject}
        workProgress={workProgress}
        totalIncome={totalIncome}
        investmentStructure={investmentStructure}
        documents={documents}
        periods={periods}
        photographicAdvance={photographicAdvance}
        getAvanceFotografico={getAvanceFotografico}
        downloadFile={downloadFile}
        downloadFilePdf={downloadFilePdf}
      />
      <VideoModal
        show={showVideoModal.status}
        selectedKey={showVideoModal.key}
        selectedIndex={showVideoModal.index}
        onClose={closeVideoModal}
        photographicAdvance={photographicAdvance}
      />
    </>
  );
}

export default FoundProject;
